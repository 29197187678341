<template>
  <Popup
    size="lg"
    modal-id="bookmark-create"
    custom-class="gray-header"
    :on-close="close"
    :scrollable="true"
    :toggle-status="showPopup"
    :x-close-btn="false"
  >
    <template v-slot:header>
      <div class="bundle-module-remark__header">
        <div class="title">{{ labels.create_bookmark }}</div>
      </div>
    </template>
    <template v-slot:body>
      <FormulateForm id="create-bundle-module-remark">
        <FormulateInput
          type="textarea"
          name="Note"
          v-model="values.text"
          :label="labels.title"
          error-behavior="blur"
          class="fontfam"
        />
      </FormulateForm>
    </template>
    <template v-slot:footer>
      <div class="fontfam">
        {{moduleName}}
      </div>
      <div class="bookmark-buttons">
        <Button
          :label="labels.cancel"
          variant="btn-outline-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          :action="close"
        />
        <Button
          :disabled="createBtnDisabled"
          :label="labels.create"
          variant="btn-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          :action="createBookmarks"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BookmarkCreate",
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      createBtnDisabled: false,
      showPopup: true,
      values: {
        text: "",
      },
    };
  },
  methods: {
    ...mapActions("account", ["createBookmark", "getModule"]),
    close() {
      this.showPopup = false;
      this.values.text = "";
      this.redirectToThePreviousPage();
    },
    createBookmarks() {
      this.createBookmark({
        ...this.values,
        module: this.$route.params.module_id,
      }).then(() => {
        this.redirectToThePreviousPage();
      });
    },
    redirectToThePreviousPage() {
      if (this.$route.name === 'bundle-bookmark-create') this.$router.push({
        name: 'bundle',
        params: {
          monetization_bundle_id: this.$route.params.monetization_bundle_id
        }
      });

      if (this.$route.name === 'bundle-module-bookmark-create') this.$router.push({
        name: 'bundle-module',
        params: {
          monetization_bundle_id: this.$route.params.monetization_bundle_id,
          module_id: this.$route.params.module_id
        }
      });
    },
  },
  computed: {
    ...mapGetters( 'helper', ['labels'] ),
    ...mapState( 'account', ['module']),
    moduleName() {
      if (this.module?.title) return this.module.title;

      return '';
    }
  },
  mounted() {
    this.getModule(this.$route.params.module_id).then(res => {
      if (res.error) {
        this.addNotification({ variant: "danger", msg: res.error });
      }
    }).catch((err) => console.log(err));
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .formulate-input .formulate-input-element {
    max-width: 100% !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    textarea {
      height: 100px;
    }
  }
  .modal-footer {
    justify-content: space-between;
    align-items: center;
    .bookmark-buttons {
      display: flex;
      button {
        margin: 0.25rem;
      }
    }
  }
}

.fontfam {
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0.1em;
}
</style>
